import '@/assets/sass/davada.scss';

import Vue from 'vue';
import App from './App.vue';
import { router } from './router';
import { store } from './store';

import './plugins/vxValidator';
import VxForm from './plugins/vxForm';
import VxHttp from './plugins/vxHttp';
import VxHttpDavada from './plugins/vxHttpDavada';
import VxDialog from './plugins/vxDialog';

import Location from '@/components/common/Location.vue';

//컴포넌트
import InputsVX from '@/components/ui/InputsVX.vue';
import OpenWindow from '@/components/common/OpenWindow.vue';
import GridVxScroll from '@/components/common/GridVXScroll';
Vue.component('InputsVX', InputsVX);
Vue.component('OpenWindow', OpenWindow);
Vue.component('GridVxScroll', GridVxScroll);

import VueMoment from 'vue-moment';
import moment from 'moment';
import 'moment/locale/ko';

import VCalendar from 'v-calendar';

Vue.use(VxDialog);
Vue.use(VxForm);
Vue.use(VxHttp, { router });
Vue.use(VxHttpDavada, { router });

moment.locale('ko');
Vue.use(VueMoment, { moment });
Vue.use(VCalendar);

Vue.component('Location', Location);

Vue.config.productionTip = false;

const app = new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app');

window.app = app;
