<template>
  <input
    :id="id"
    :type="type === 'price' || type === 'minus' ? 'tel' : type"
    @input="bindNumber"
    :value="inputValue"
    :placeholder="placeholder"
    @focus="onFocus"
    @blur="onBlur"
    @keyup.enter="onEnter"
  />
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      default: null,
    },
    type: {
      type: String,
      default: 'text',
    },
    id: {
      id: String,
      default: '',
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  methods: {
    comma(val) {
      return String(val).replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
    },
    uncomma(str) {
      return String(str).replace(/[^\d]+/g, '');
    },
    // 키입력에따라서 데이타 바인딩처리
    bindNumber($event) {
      var value = $event.target.value;
      console.log('inputVx bindNumber value: ' + value);
      if (this.type === 'business') {
        if (value.length >= 10) {
          value = value.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
        }
      }
      if (this.type === 'tel') {
        if (value.length >= 11) {
          value = value.replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
        } else if (value.length == 10) {
          value = value.replace(/^(\d{2,2})(\d{3,3})(\d{4})$/, `$1-$2-$3`);
        }
      }
      //
      if (this.type === 'price') {
        value = this.setPrice(value);
      }
      this.$emit('input', value);
      //this.$emit('inputChange');
    },
    //
    setPrice(value) {
      var strValue = String(value);
      var splitValue = strValue.split('.');
      var currency = +splitValue[0].replace(/[^\d]/g, '').toString();
      var result = Intl.NumberFormat().format(currency);
      var resultPrice = '';
      if (splitValue[1] === undefined || splitValue[1].length < 1) {
        resultPrice = result;
      } else {
        resultPrice = result + '.' + splitValue[1];
      }
      resultPrice = value < 0 ? '-' + resultPrice : resultPrice;
      console.log(
        'inputVx watch setPrice value: ' +
          value +
          ', resultPrice: ' +
          resultPrice,
      );
      return resultPrice;
    },
    onFocus() {
      if (this.type === 'number' || this.type === 'price') {
        if (this.value == 0) {
          this.$emit('input', '');
        }
      }
      this.$emit('focus');
    },
    onBlur() {
      if (this.type === 'number' || this.type === 'price') {
        if (this.value == 0) {
          this.$emit('input', 0);
        }
      }
      this.$emit('blur');
    },
    onEnter($event) {
      // var value = $event.target.value;
      // console.log('onEnter value:' + value);
      this.$emit('onEnter', $event);
    },
  },
  watch: {
    value(val) {
      console.log('inputVx watch setPrice val: ' + val);
      this.inputValue = this.value;
      if (this.type === 'number') {
        const reg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z]/;
        // old버전 props에러 발생
        // if (reg.exec(val) !== null) this.value = val.replace(/[^0-9]/g, '');
        // if (isNaN(parseFloat(val))) this.value = '';
        if (reg.exec(val) !== null)
          this.inputValue = val.replace(/[^0-9]/g, '');
        if (isNaN(parseFloat(val))) this.inputValue = '';
      }
      if (this.type === 'price') {
        this.inputValue = this.setPrice(this.value);
        this.$emit('input', this.inputValue);
      }
      // 판매/매입시 환입(반품),환출(반납) -(마이너스) 표기위함.
      if (this.type === 'minus') {
        this.inputValue = this.setPrice(this.value);
      }
    },
  },
};
</script>
