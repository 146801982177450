<template>
  <div class="grid-bottom" v-show="gridVisible">
    <!--
        :suppressRowClickSelection="rowSelection === 'multiple'"
        :enableRangeSelection="true"
        :allowContextMenuWithControlKey="allowContextMenu"
        :getContextMenuItems="getContextMenuItems"
     -->
    <ag-grid-vue
      class="ag-theme-balham"
      :gridOptions="gridOptions"
      :columnDefs="getColumns"
      :rowData="rows"
      :rowSelection="rowSelection"
      :defaultColDef="defaultColDef"
      :overlayLoadingTemplate="overlayLoadingTemplate"
      :frameworkComponents="frameworkComponents"
      :context="context"
      @sortChanged="onSortChanged"
      @selection-changed="onSelectionChanged"
      @rowClicked="onRowClicked"
      :tooltipShowDelay="0"
      :enableBrowserTooltips="true"
      :pagination="pagination"
      :paginationAutoPageSize="paginationAutoPageSize"
      :paginationPageSize="paginationPageSize"
      :treeData="treeData"
      :animateRows="true"
      :autoGroupColumnDef="autoGroupColumnDef"
      :groupDefaultExpanded="groupDefaultExpanded"
      :getDataPath="getDataPath"
      :getRowStyle="getRowStyle"
      :pinnedTopRowData="pinnedTopRowData"
      :pinnedBottomRowData="pinnedBottomRowData"
      @first-data-rendered="onFirstDataRendered"
      :getRowId="getRowId"
      @grid-ready="onGridReady"
      :showOpenedGroup="showOpenedGroup"
      :groupDisplayType="groupDisplayType"
      :rowGroupPanelShow="rowGroupPanelShow"
      :groupIncludeFooter="groupIncludeFooter"
      :groupIncludeTotalFooter="groupIncludeTotalFooter"
    ></ag-grid-vue>
  </div>
</template>
<script>
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey(
  'CompanyName=MiraeWeb Inc._on_behalf_of_Davada Co., Ltd.,LicensedApplication=Text order management team,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-035008,SupportServicesEnd=18_November_2023_[v2]_MTcwMDI2NTYwMDAwMA==acbe92b70e8f8af7bb2df844bc6cc28a',
);
import { AgGridVue } from 'ag-grid-vue';

export default {
  components: {
    //AgGridVue,
    'ag-grid-vue': AgGridVue,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    rows: {
      type: Array,
      default: () => [],
    },
    disabledSortable: {
      type: Boolean,
      default: null,
    },
    frameworkComponents: {
      type: Object,
      default: () => null,
    },
    context: {
      type: Object,
      default: () => {},
    },
    getRowId: null,
    rowSelection: {
      type: String,
      default: 'multiple',
    },
    rowSelected: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    paginationAutoPageSize: {
      type: Boolean,
      default: true,
    },
    defaultCol: {
      type: Object,
      default: () => {
        return { suppressMovable: true, resizable: false };
      },
    },
    treeData: {
      type: Boolean,
      default: false,
    },
    autoGroupColumnDef: {
      type: Object,
      default: () => null,
    },
    groupDefaultExpanded: {
      type: Number,
      default: -1,
    },
    groupField: {
      type: String,
      default: '',
    },
    getRowStyle: {
      type: String,
      default: '',
    },
    pinnedTopRowData: {
      type: Array,
      default: null,
    },
    pinnedBottomRowData: {
      type: Array,
      default: null,
    },
    headerHeight: {
      type: Number,
      default: 37,
    },
    rowScroll: {
      type: Boolean,
      default: false,
    },
    // add kang 2022-09-14
    allowContextMenu: {
      type: Boolean,
      default: true,
    },
    paginationPageSize: {
      type: Number,
      default: 18,
    },
    // 그룹컬럼 하단에 같은데이타 표시됨(중복표시개념)
    showOpenedGroup: {
      type: Boolean,
      default: false,
    },
    // 그룹멀티컬럼처리(multipleColumns)
    groupDisplayType: {
      type: String,
      default: '',
    },
    // 상단에 그룹컬럼 drag처리 표시
    rowGroupPanelShow: {
      type: String,
      default: '',
    },
    // 소계및 총계합계처리(이걸 쓸려면 column에서 aggFunc: 'sum'을 지정해야함. )
    groupIncludeFooter: {
      type: Boolean,
      default: false,
    },
    groupIncludeTotalFooter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gridVisible: false,
      defaultColDef: this.defaultCol,
      sortColumn: null,
      sortOrder: null, //1:Ascending, 2:Descending
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      overlayLoadingTemplate:
        '<span class="ag-overlay-loading-center"><div class="loader">Loading...</div></span>',
      selectedData: null,
      getDataPath: null,
      isRowSelectable: null,
      //
      getContextMenuItems: null,
    };
  },
  methods: {
    onRowClick(event) {
      if (event.targetType === 'cell') {
        const { rowKey } = event;
        rowKey;
        this.$emit('rowClick', this.rows[rowKey]);
      }
    },
    onSortChanged(e) {
      this.sortColumn = null;
      this.sortOrder = null;
      e.columnApi.getColumnState().forEach(c => {
        if (c.sort !== null) {
          this.sortColumn = c.colId;
          this.sortOrder = c.sort === 'asc' ? 1 : 2;
          return false;
        }
      });
      this.doRefresh();
    },
    doRefresh() {
      let params = {
        orderBy: this.sortColumn,
        sort: this.sortOrder,
      };
      this.$emit('refresh', params);
      // console.log('refresh', params);
    },
    camelToSnakeCase(field) {
      return field.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    },
    sizeToFit() {
      if (!this.rowScroll) this.gridApi.sizeColumnsToFit();
    },
    onSelectionChanged() {
      var selectedRows = this.gridApi.getSelectedRows();
      this.$emit('selectionChanged', selectedRows);
    },
    onRowClicked() {
      var selectedRows = this.gridApi.getSelectedRows();
      // console.log('selectedRows=', selectedRows);
      this.$emit('rowClicked', selectedRows);
    },
    initRowSelected() {
      setTimeout(() => {
        let nodes = this.gridApi.getRenderedNodes();
        if (nodes.length > 0) {
          nodes[0].setSelected(true);
          let selectedNodes = this.gridApi.getSelectedNodes();
          this.selectedData = selectedNodes.map(node => node.data);
        }
      }, 100);
      /* setTimeout(() => {
        this.gridApi.forEachNode(function (node) {
          node.setSelected(node.id == 0);
        });
        let selectedNodes = this.gridApi.getSelectedNodes();
        this.selectedData = selectedNodes.map(node => node.data);
      }, 100); */
    },
    getRowSelected() {
      /*
      let selectedNodes = this.gridApi.getSelectedNodes();
      this.selectedData = selectedNodes.map(node => node.data);
      return this.selectedData;
      */
      let selectedNodes = this.gridApi.getSelectedNodes();
      const checkedData = selectedNodes.map(node => node.data);
      return checkedData;
    },
    onDoubleClick() {
      this.$emit('doubleClickData', this.selectedData);
    },
    onFirstDataRendered() {
      this.sizeToFit();
    },
    onGridReady() {
      this.$emit('onGridReady');
    },
  },
  computed: {
    getColumns() {
      const disabled = this.disabledSortable;
      if (disabled === null) {
        return this.columns;
      } else {
        return this.columns.map(c => ({ ...c, sortable: !disabled }));
      }
    },
  },
  created() {
    if (this.treeData) {
      this.getDataPath = data => {
        return data[this.groupField];
      };
    }
    setTimeout(this.sizeToFit);
    window.addEventListener('resize', this.sizeToFit);
    setTimeout(() => {
      this.gridVisible = true;
    });
  },
  beforeMount() {
    this.gridOptions = {
      pagination: false,
      paginationAutoPageSize: false,
      // domLayout: 'autoHeight',
      headerHeight: this.headerHeight,
      rowHeight: 32,
      localeText: { noRowsToShow: '데이터가 없습니다.' },
      suppressContextMenu: true,
      onRowDoubleClicked: this.onDoubleClick,
      wrapHeaderText: true,
    };
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    //this.columnApi = this.gridOptions.columnApi;
    // setTimeout(this.sizeToFit, 100);
    // window.addEventListener('resize', this.sizeToFit);
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.sizeToFit);
  },
  watch: {
    rows() {
      setTimeout(this.sizeToFit, 0);
      if (this.rowSelected) {
        this.initRowSelected();
      }
      /* this.isRowSelectable = params => {
        console.log('params=', params);
        // return !!params.data && params.data.id === 3;
      }; */
    },
    selectedData() {
      console.log('선택 로우값 변경');
      this.$emit('changeSelectedData', this.selectedData);
    },
  },
};
</script>
