import Vue from 'vue';
import VueRouter from 'vue-router';
import { getIsLogin } from '@/utils/auth';
// import { store } from '@/store';

Vue.use(VueRouter);

export const getRoutes = [
  {
    path: '/',
    name: '',
    redirect: '/login',
  },
  {
    path: '/login',
    name: '',
    component: () => import('@/views/main/login/LoginForm.vue'),
    meta: { auth: false },
  },
  {
    path: '/main',
    name: '',
    component: () => import('@/views/SubRouterView.vue'),
    redirect: '/main',
    meta: { auth: true, permission: ['ORDER_WORKER', 'MANAGER', 'ADMIN'] },
    children: [
      {
        path: '/main',
        name: '메인',
        component: () => import('@/views/main/menu/MeuTopForm.vue'),
        meta: { auth: true, permission: ['ORDER_WORKER', 'MANAGER', 'ADMIN'] },
      },
    ],
  },
  {
    path: '/order',
    name: '주문관리',
    component: () => import('@/views/SubRouterView.vue'),
    redirect: '/order/admin',
    meta: {
      auth: true,
      permission: ['ORDER_WORKER', 'ORDER_MANAGER', 'MANAGER', 'ADMIN'],
    },
    children: [
      {
        path: '/order/admin',
        name: '주문관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/order/admin/monthlist',
        meta: {
          auth: true,
          permission: ['ORDER_WORKER', 'ORDER_MANAGER', 'MANAGER', 'ADMIN'],
        },
        children: [
          {
            path: '/order/admin/monthlist',
            name: '주문관리',
            component: () => import('@/views/order/order/OrderMng.vue'),
            meta: {
              auth: true,
              permission: ['ORDER_WORKER', 'ORDER_MANAGER', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/order/admin/retailshop',
            name: '매출거래처관리',
            component: () =>
              import('@/views/order/admin/retailshop/RetailshopMang.vue'),
            meta: {
              auth: true,
              permission: ['ORDER_WORKER', 'ORDER_MANAGER', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          // {
          //   path: '/order/admin/daylist',
          //   name: '거래처 주문관리',
          //   component: () =>
          //     import('@/views/order/admin/AccountOrderMng.vue'),
          //   meta: {
          //     auth: true,
          //     permission: ['USER-00', 'ADMIN'],
          //     usePop: false,
          //   },
          // },
        ],
      },
      {
        path: '/order/ars',
        name: '착신관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/order/ars/callchngmang',
        meta: {
          auth: true,
          permission: ['MANAGER', 'ORDER_WORKER', 'ORDER_MANAGER', 'ADMIN'],
        },
        children: [
          {
            path: '/order/ars/callchngmang',
            name: '착신관리',
            component: () => import('@/views/order/ars/CallChngMangForm.vue'),
            meta: {
              auth: true,
              permission: ['ORDER_WORKER', 'ORDER_MANAGER', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/order/ars/mentmang',
            name: '멘트관리',
            component: () => import('@/views/order/ars/MentMangForm.vue'),
            meta: {
              auth: true,
              permission: ['ORDER_WORKER', 'ORDER_MANAGER', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
      {
        path: '/order/admin',
        name: '관리자',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/order/ars/callchngmang',
        meta: { auth: true, permission: ['ORDER_MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/order/admin/retailshop',
            name: '매출거래처관리',
            component: () =>
              import('@/views/order/admin/retailshop/RetailshopMang.vue'),
            meta: {
              auth: true,
              permission: ['ORDER_MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/order/admin/employee',
            name: '영업담당자관리',
            component: () =>
              import('@/views/order/admin/employee/EmployeeMang.vue'),
            meta: {
              auth: true,
              permission: ['ORDER_MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/order/admin/callchng',
            name: '착신목록관리',
            component: () =>
              import('@/views/order/admin/callchng/CallchngMang.vue'),
            meta: {
              auth: true,
              permission: ['ORDER_MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/order/admin/account',
            name: '거래처관리[old]',
            component: () => import('@/views/order/admin/AccountOrderMng.vue'),
            meta: {
              auth: true,
              permission: ['ORDER_MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/sales',
    name: '매출관리',
    component: () => import('@/views/SubRouterView.vue'),
    redirect: '/sales/salesmang',
    meta: { auth: true, permission: ['MANAGER', 'SALES', 'ADMIN'] },
    children: [
      {
        path: '/sales/salesmang',
        name: '판매관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/sales/salesmang/salesslip',
        meta: { auth: true, permission: ['SALES', 'MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/sales/salesmang/salesslip',
            name: '판매전표관리',
            component: () => import('@/views/sales/sales/SalesMang.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/sales/deposit/dsdepositmang',
            name: '판매입금관리',
            component: () => import('@/views/sales/deposit/DpstgMang.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/sales/deposit/dpstgmang',
            name: '데일리입금관리',
            component: () =>
              import('@/views/sales/dsdeposit/DsPaymentMang.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/sales/salesmang/salesmang',
            name: '판매전표관리(상하단)',
            component: () =>
              import('@/views/sales/salesmang/SalesSlipTransrList.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
      {
        path: '/sales/salesstatus',
        name: '판매현항',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/sales/salesstatus/salesview',
        meta: { auth: true, permission: ['SALES', 'MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/sales/overall/salesoverallmang',
            name: '판매현항종합(분석)',
            component: () =>
              import('@/views/sales/overall/SalesOverallMang.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/sales/salesstatus/salesview',
            name: '판매현항종합(old)',
            component: () =>
              import('@/views/sales/salesstatus/SalesSlipRptList.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
      {
        path: '/sales/boxbottle',
        name: '용기공병관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/sales/collect/BoxBtlCollectMang',
        meta: { auth: true, permission: ['MANAGER', 'SALES', 'ADMIN'] },
        children: [
          {
            path: '/sales/collect/BoxBtlCollectMang',
            name: '용공회수관리',
            component: () => import('@/views/sales/collect/CollectMang.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/sales/collect/overall/CollectOverallMang',
            name: '용공회수현황(분석)',
            component: () =>
              import('@/views/sales/collect/overall/CollectOverallMang.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
      {
        path: '/sales/bond',
        name: '매출채권관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/sales/bond/bondledger',
        meta: { auth: true, permission: ['SALES', 'MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/sales/bond/bondledger',
            name: '매출처별채권원장',
            component: () =>
              import(
                '@/views/sales/bond/SalesBondLedgerView/SalesBondLedgerView.vue'
              ),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/sales/salesbondmang/bondview',
            name: '매출처채권현황',
            component: () =>
              import(
                '@/views/sales/bond/SalesBondRetailShopView/SalesBondRetailShopView.vue'
              ),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
      {
        path: '/sales/loan',
        name: '대여금관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/sales/loan/LoanDrwgMang',
        meta: { auth: true, permission: ['MANAGER', 'SALES', 'ADMIN'] },
        children: [
          {
            path: '/sales/loan/LoanDrwgMang',
            name: '대여금관리',
            component: () => import('@/views/sales/loan/LoanDrwgMang.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
      {
        path: '/sales/taxinvoice',
        name: '세금계산서관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/bond/taxinvoice/taxinvoice',
        meta: { auth: true, permission: ['SALES', 'MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/sales/taxinvoice/TaxInvoiceMang',
            name: '세금계산서목록',
            component: () =>
              import('@/views/sales/taxinvoice/TaxInvoiceMang.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/sales/taxinvoice/pobbill',
            name: '세금계산서바로가기',
            component: () => import('@/views/TaxPopBillRedirectView.vue'),
            meta: {
              auth: false,
              permission: ['TAX', 'MANAGER', 'ADMIN'],
              usePop: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/purchase',
    name: '매입관리',
    component: () => import('@/views/SubRouterView.vue'),
    redirect: '/purchase/purchase',
    meta: { auth: true, permission: ['SALES', 'MANAGER', 'ADMIN'] },
    children: [
      {
        path: '/purchase/purchase',
        name: '매입관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/purchase/purchase/purchaseslip',
        meta: { auth: true, permission: ['SALES', 'MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/purchase/purchase/purchaseslip',
            name: '매입전표관리',
            component: () =>
              import('@/views/purchase/purchase/PurchaseMang.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/purchase/payment/paymentmang',
            name: '구매출금관리',
            component: () => import('@/views/purchase/payment/PaymentMang.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
      {
        path: '/purchase/overall',
        name: '구매현항',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/purchase/overall/purchaseoverall',
        meta: { auth: true, permission: ['SALES', 'MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/purchase/overall/purchaseoverall',
            name: '구매현항종합(분석)',
            component: () =>
              import('@/views/purchase/overall/PurchaseOverallMang.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/sales/salesstatus/salesview',
            name: '구매현항종합(old)',
            component: () =>
              import('@/views/sales/salesstatus/SalesSlipRptList.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
      {
        path: '/purchase/return',
        name: '용공관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/purchase/return/return',
        meta: { auth: true, permission: ['SALES', 'MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/purchase/return/return',
            name: '용공반납관리',
            component: () => import('@/views/purchase/return/ReturnMang.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/purchase/return/overall',
            name: '용공반납현황(분석)',
            component: () =>
              import('@/views/purchase/return/overall/ReturnOverallMang.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
      {
        path: '/purchase/debt',
        name: '매입처채무관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/sales/debt/bondledger',
        meta: { auth: true, permission: ['SALES', 'MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/sales/debt/bondledger',
            name: '매입처별채무원장',
            component: () =>
              import(
                '@/views/purchase/debt/PurchaseDebtLedgerView/PurchaseDebtLedgerView.vue'
              ),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/purchase/debt/bondview',
            name: '매입처채무현황',
            component: () =>
              import(
                '@/views/purchase/debt/PurchaseDebtSupplierView/PurchaseDebtSupplierView.vue'
              ),
            meta: {
              auth: true,
              permission: ['SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
    ],
  },
  /*
  {
    path: '/bond',
    name: '채권관리',
    component: () => import('@/views/SubRouterView.vue'),
    redirect: '/bond/salesbondmang',
    meta: { auth: true, permission: ['USER-00'] },
    children: [
      {
        path: '/bond/salesbondmang',
        name: '영업채권관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/bond/salesbondmang/bondledger',
        meta: { auth: true, permission: ['USER-00', 'ADMIN'] },
        children: [
          {
            path: '/bond/salesbondmang/bondledger',
            name: '매출처별채권원장',
            component: () =>
              import(
                '@/views/bond/salesbondmang/PurchaseDebtLedgerView.vue'
              ),
            meta: {
              auth: true,
              permission: ['USER-00', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/bond/salesbondmang/bondview',
            name: '매출처채권현황',
            component: () =>
              import('@/views/bond/salesbondmang/PurchaseDebtSupplierView.vue'),
            meta: {
              auth: true,
              permission: ['USER-00', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
      {
        path: '/bond/loan',
        name: '대여금관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/bond/loan/loan',
        meta: { auth: true, permission: ['USER-00', 'ADMIN'] },
        children: [
          {
            path: '/bond/loan/LoanDrwgMang',
            name: '대여금관리',
            component: () => import('@/views/bond/loan/LoanDrwgMang.vue'),
            meta: {
              auth: true,
              permission: ['USER-00', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
      {
        path: '/bond/taxmang',
        name: '세금계산서관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/bond/taxmang/taxinvoice',
        meta: { auth: true, permission: ['USER-00', 'ADMIN'] },
        children: [
          {
            path: '/bond/taxmang/taxinvoice',
            name: '세금계산서처리목록',
            component: () =>
              import('@/views/bond/taxmang/TaxInvoiceTransrList.vue'),
            meta: {
              auth: true,
              permission: ['USER-00', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/bond/taxmang/taxdetail',
            name: '전자세금계산서작성폼',
            component: () =>
              import('@/views/bond/taxmang/TaxInvoiceTransrList.vue'),
            meta: {
              auth: true,
              permission: ['USER-00', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
    ],
  },
  */
  {
    path: '/stock',
    name: '재고관리',
    component: () => import('@/views/SubRouterView.vue'),
    redirect: 'stock/optimum',
    meta: { auth: true, permission: ['WAREHOUSE', 'MANAGER', 'ADMIN'] },
    children: [
      {
        path: '/stock/release',
        name: '상품출고관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/stock/optimum/OptimumStockMang',
        meta: { auth: true, permission: ['WAREHOUSE', 'MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/stock/release/SalesProductReleaseMang',
            name: '상품출고관리',
            component: () =>
              import('@/views/stock/release/SalesProductReleaseMang'),
            meta: {
              auth: true,
              permission: ['WAREHOUSE', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
      {
        path: '/stock/optimum',
        name: '상품재고관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/stock/optimum/OptimumStockMang',
        meta: { auth: true, permission: ['WAREHOUSE', 'MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/stock/optimum/OptimumStockMang',
            name: '상품적정재고관리',
            component: () =>
              import('@/views/stock/optimum/OptimumStockMang.vue'),
            meta: {
              auth: true,
              permission: ['WAREHOUSE', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/stock/stockviews/ProductStockMang',
            name: '상품재고현황',
            component: () =>
              import('@/views/stock/productstock/ProductStockMang.vue'),
            meta: {
              auth: true,
              permission: ['WAREHOUSE', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          // {
          //   path: '/stock/stockviews/inout/ProductInOutMang',
          //   name: '상품수불현황',
          //   component: () =>
          //     import('@/views/stock/stockviews/inout/ProductInOutMang.vue'),
          //   meta: {
          //     auth: true,
          //     permission: ['MANAGER', 'WAREHOUSE', 'ADMIN'],
          //     usePop: false,
          //   },
          // },
          {
            path: '/stock/stockviews/ProductStockPivotMang',
            name: '상품재고현황(분석)',
            component: () =>
              import('@/views/stock/stockviews/ProductStockPivotMang.vue'),
            meta: {
              auth: true,
              permission: ['WAREHOUSE', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
      {
        path: '/stock/boxbottlestock',
        name: '용공재고관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/stock/stockviews/BoxbottleStockMang',
        meta: { auth: true, permission: ['WAREHOUSE', 'MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/stock/boxbottlestock/BoxbottleStockMang',
            name: '용공재고현황',
            component: () =>
              import('@/views/stock/boxbottlestock/BoxBottleStockMang.vue'),
            meta: {
              auth: true,
              permission: ['WAREHOUSE', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/groupware',
    name: '그룹웨어',
    component: () => import('@/views/SubRouterView.vue'),
    redirect: 'aiditem/asreq',
    meta: { auth: true, permission: ['MANAGER', 'ADMIN'] },
    children: [
      {
        path: '/approval',
        name: '결재관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/groupware/approval/docs',
        meta: { auth: true, permission: ['MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/groupware/approval/ApprovalRecvDocMang',
            name: '수신문서목록',
            component: () =>
              import('@/views/groupware/approval/ApprovalRecvDocMang.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
      {
        path: '/groupware/board',
        name: '공지관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/groupware/board/board',
        meta: { auth: true, permission: ['MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/source/board/board',
            name: '공지사항관리',
            component: () => import('@/views/groupware/board/BoardMang.vue'),
            meta: {
              auth: true,
              permission: ['MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/ceo',
    name: '경영관리',
    component: () => import('@/views/SubRouterView.vue'),
    redirect: 'aiditem/asreq',
    meta: { auth: true, permission: ['MANAGER', 'ADMIN'] },
    children: [
      {
        path: '/imis',
        name: '경영자원관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/imis/bonddebt/BondDebtMang',
        meta: { auth: true, permission: ['MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/imis/bonddebt/BondDebtMang',
            name: '채권분석현황',
            component: () => import('@/views/imis/BondDebtMang.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/sales/overall/salesoverallmang',
            name: '경영자료분석현황(피봇)',
            component: () =>
              import('@/views/sales/overall/SalesOverallMang.vue'),
            meta: {
              auth: true,
              permission: ['SALES', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/additem',
    name: '지원품관리',
    component: () => import('@/views/SubRouterView.vue'),
    redirect: 'aiditem/asreq',
    meta: {
      auth: true,
      permission: ['ORDER_WORKER', 'SALES', 'MANAGER', 'ADMIN'],
    },
    children: [
      {
        path: '/aiditem/asreq',
        name: '지원품관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/aiditem/asreq/OptimumInventoryMang',
        meta: {
          auth: true,
          permission: ['ORDER_WORKER', 'SALES', 'MANAGER', 'ADMIN'],
        },
        children: [
          {
            path: '/aiditem/asreq/AsReqMang',
            name: 'AS요청관리',
            component: () => import('@/views/aiditem/asreq/AsReqMang.vue'),
            meta: {
              auth: true,
              permission: ['ORDER_WORKER', 'SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/aiditem/asretailshop/AsRetailShopMang',
            name: '납품처AS현황',
            component: () =>
              import('@/views/aiditem/asretailshop/AsRetailShopMang.vue'),
            meta: {
              auth: true,
              permission: ['ORDER_WORKER', 'SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/aiditem/overhaul/OverHaulMang',
            name: '정비(폐기)내역',
            component: () =>
              import('@/views/aiditem/overhaul/OverHaulMang.vue'),
            meta: {
              auth: true,
              permission: ['ORDER_WORKER', 'SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/aiditem/stockview/StockViewMang',
            name: '창고재고현황',
            component: () =>
              import('@/views/aiditem/stockview/StockViewMang.vue'),
            meta: {
              auth: true,
              permission: ['ORDER_WORKER', 'SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/aiditem/bondview/BondViewMang',
            name: '월별채권현황',
            component: () =>
              import('@/views/aiditem/bondview/BondViewMang.vue'),
            meta: {
              auth: true,
              permission: ['ORDER_WORKER', 'SALES', 'MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/aiditem/aidviews/PivotMang',
            name: '지원품현황분석',
            component: () => import('@/views/source/analysis/PivotMang.vue'),
            meta: {
              auth: true,
              permission: ['MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/source',
    name: '기초자료관리',
    component: () => import('@/views/SubRouterView.vue'),
    redirect: 'source/finmang',
    meta: { auth: true, permission: ['MANAGER', 'ADMIN'] },
    children: [
      {
        path: '/admin/company',
        name: '업체관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/admin/company/wholesaler',
        meta: { auth: true, permission: ['MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/admin/company/wholesaler',
            name: '업체정보관리',
            component: () =>
              import('@/views/admin/company/wholesaler/WholesalerMang.vue'),
            meta: {
              auth: true,
              permission: ['ADMIN'],
              usePop: true,
            },
          },
          {
            path: '/admin/company/employee',
            name: '사용자관리',
            component: () =>
              import('@/views/admin/company/employee/EmployeeMang.vue'),
            meta: {
              auth: true,
              permission: ['MANAGER', 'ADMIN'],
              usePop: true,
            },
          },
          {
            path: '/admin/company/obs',
            name: '조직도관리',
            component: () => import('@/views/admin/company/obs/ObsMang.vue'),
            meta: {
              auth: true,
              permission: ['MANAGER', 'ADMIN'],
              usePop: true,
            },
          },
        ],
      },
      {
        path: '/admin/account',
        name: '거래처관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/admin/customer',
        meta: { auth: true, permission: ['MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/admin/customer/retailshop',
            name: '매출거래처관리',
            component: () =>
              import('@/views/admin/customer/retailshop/RetailshopMang.vue'),
            meta: {
              auth: true,
              permission: ['MANAGER', 'ADMIN'],
              usePop: true,
            },
          },
          {
            path: '/admin/customer/supplier',
            name: '매입거래처관리',
            component: () =>
              import('@/views/admin/customer/supplier/SupplierMang.vue'),
            meta: {
              auth: true,
              permission: ['MANAGER', 'ADMIN'],
              usePop: true,
            },
          },
        ],
      },
      {
        path: '/admin/items',
        name: '품목관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/admin/items/items',
        meta: { auth: true, permission: ['MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/admin/items/product',
            name: '주류품목관리',
            component: () =>
              import('@/views/admin/items/product/ProductMang.vue'),
            meta: {
              auth: true,
              permission: ['MANAGER', 'ADMIN'],
              usePop: true,
            },
          },
          {
            path: '/admin/items/productUniprc',
            name: '품목단가변경관리',
            component: () =>
              import(
                '@/views/admin/items/productUniprc/ProductUniprcChngMang.vue'
              ),
            meta: {
              auth: true,
              permission: ['MANAGER', 'ADMIN'],
              usePop: true,
            },
          },
          {
            path: '/admin/items/boxbtl',
            name: '용기공병관리',
            component: () =>
              import('@/views/admin/items/boxbtl/BoxBtlMang.vue'),
            meta: {
              auth: true,
              permission: ['MANAGER', 'ADMIN'],
              usePop: true,
            },
          },
        ],
      },
      {
        path: '/admin/setup',
        name: '코드관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/admin/setup/commoncode',
        meta: { auth: true, permission: ['MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/admin/warehouse/warehouse',
            name: '창고코드관리',
            component: () =>
              import('@/views/admin/warehouse/WareHouseMang.vue'),
            meta: {
              auth: true,
              permission: ['MANAGER', 'ADMIN'],
              usePop: true,
            },
          },
          {
            path: '/admin/setup/commoncode',
            name: '공통코드관리',
            component: () =>
              import('@/views/admin/setup/commoncode/CommoncodeMang.vue'),
            meta: {
              auth: true,
              permission: ['MANAGER', 'ADMIN'],
              usePop: true,
            },
          },
        ],
      },
      {
        path: '/source/finmang',
        name: '마감관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/source/finmang/monthfin',
        meta: { auth: true, permission: ['MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/source/finmang/monthfin',
            name: '월마감관리',
            component: () =>
              import('@/views/source/finmang/MonthClosingForm.vue'),
            meta: {
              auth: true,
              permission: ['MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
      {
        path: '/source/board',
        name: '공지관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/source/board/board',
        meta: { auth: true, permission: ['MANAGER', 'ADMIN'] },
        children: [
          {
            path: '/source/board/board',
            name: '공지사항관리',
            component: () => import('@/views/groupware/board/BoardMang.vue'),
            meta: {
              auth: true,
              permission: ['MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/source/board/pivot',
            name: '분석현황(피봇)',
            component: () => import('@/views/source/analysis/PivotMang.vue'),
            meta: {
              auth: true,
              permission: ['MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
          {
            path: '/source/board/pivotgroup',
            name: '분석현황(그룹)',
            component: () =>
              import('@/views/source/analysis/PivotGroupSample.vue'),
            meta: {
              auth: true,
              permission: ['MANAGER', 'ADMIN'],
              usePop: false,
            },
          },
        ],
      },
    ],
  },
  {
    path: '/admin',
    name: '시스템 설정',
    component: () => import('@/views/SubRouterView.vue'),
    redirect: '/admin/company',
    meta: { auth: true, permission: ['ADMIN'] },
    children: [
      {
        path: '/admin/company',
        name: '업체관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/admin/company/wholesaler',
        meta: { auth: true, permission: ['ADMIN'] },
        children: [
          {
            path: '/admin/company/wholesaler',
            name: '업체정보관리',
            component: () =>
              import('@/views/admin/company/wholesaler/WholesalerMang.vue'),
            meta: {
              auth: true,
              permission: ['ADMIN'],
              usePop: true,
            },
          },
          {
            path: '/admin/company/employee',
            name: '사용자관리',
            component: () =>
              import('@/views/admin/company/employee/EmployeeMang.vue'),
            meta: {
              auth: true,
              permission: ['ADMIN'],
              usePop: true,
            },
          },
          {
            path: '/admin/company/obs',
            name: '조직도관리',
            component: () => import('@/views/admin/company/obs/ObsMang.vue'),
            meta: {
              auth: true,
              permission: ['ADMIN'],
              usePop: true,
            },
          },
        ],
      },
      {
        path: '/admin/customer',
        name: '거래처관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/admin/customer/retailshop',
        meta: { auth: true, permission: ['ADMIN'] },
        children: [
          {
            path: '/admin/customer/retailshop',
            name: '매출거래처관리',
            component: () =>
              import('@/views/admin/customer/retailshop/RetailshopMang.vue'),
            meta: {
              auth: true,
              permission: ['ADMIN'],
              usePop: true,
            },
          },
          {
            path: '/admin/customer/supplier',
            name: '매입거래처관리',
            component: () =>
              import('@/views/admin/customer/supplier/SupplierMang.vue'),
            meta: {
              auth: true,
              permission: ['ADMIN'],
              usePop: true,
            },
          },
        ],
      },
      {
        path: '/admin/item',
        name: '품목관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/admin/items/product',
        meta: { auth: true, permission: ['ADMIN'] },
        children: [
          {
            path: '/admin/items/product',
            name: '주류품목관리',
            component: () =>
              import('@/views/admin/items/product/ProductMang.vue'),
            meta: {
              auth: true,
              permission: ['ADMIN'],
              usePop: true,
            },
          },
          {
            path: '/admin/items/productUniprc',
            name: '품목단가변경관리',
            component: () =>
              import(
                '@/views/admin/items/productUniprc/ProductUniprcChngMang.vue'
              ),
            meta: {
              auth: true,
              permission: ['ADMIN'],
              usePop: true,
            },
          },
          {
            path: '/admin/items/boxbtl',
            name: '용기공병관리',
            component: () =>
              import('@/views/admin/items/boxbtl/BoxBtlMang.vue'),
            meta: {
              auth: true,
              permission: ['ADMIN'],
              usePop: true,
            },
          },
        ],
      },
      {
        path: '/admin/warehouse',
        name: '창고관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/admin/warehouse/warehouse',
        meta: { auth: true, permission: ['ADMIN'] },
        children: [
          {
            path: '/admin/warehouse/warehouse',
            name: '창고관리',
            component: () =>
              import('@/views/admin/warehouse/WareHouseMang.vue'),
            meta: {
              auth: true,
              permission: ['ADMIN'],
              usePop: true,
            },
          },
        ],
      },
      {
        path: '/admin/setup',
        name: '환경설정',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/admin/setup/commoncode',
        meta: { auth: true, permission: ['ADMIN'] },
        children: [
          {
            path: '/admin/setup/commoncode',
            name: '공통코드관리',
            component: () =>
              import('@/views/admin/setup/commoncode/CommoncodeMang.vue'),
            meta: {
              auth: true,
              permission: ['ADMIN'],
              usePop: true,
            },
          },
          {
            path: '/admin/setup/basedata',
            name: '기초자료업로드',
            component: () =>
              import('@/views/admin/setup/basedata/BasedataUploadMang.vue'),
            meta: {
              auth: true,
              permission: ['ADMIN'],
              usePop: true,
            },
          },
          {
            path: '/admin/setup/bondStockUpload',
            name: '채권/재고업로드',
            component: () =>
              import(
                '@/views/admin/setup/bondStockUpload/BondStockUploadMng.vue'
              ),
            meta: {
              auth: true,
              permission: ['ADMIN'],
              usePop: true,
            },
          },
        ],
      },
      {
        path: '/admin/menus',
        name: '메뉴관리',
        component: () => import('@/views/SubRouterInnerView.vue'),
        redirect: '/admin/setup/menu',
        meta: { auth: true, permission: ['ADMIN'] },
        children: [
          {
            path: '/admin/menus/menu',
            name: '메뉴정보관리',
            component: () => import('@/views/admin/menus/MenuList.vue'),
            meta: {
              auth: true,
              permission: ['ADMIN'],
              usePop: true,
            },
          },
          {
            path: '/admin/menus/grpmenu',
            name: '그룹별메뉴권한관리',
            component: () => import('@/views/admin/menus/GrpMenuList.vue'),
            meta: {
              auth: true,
              permission: ['ADMIN'],
              usePop: true,
            },
          },
          {
            path: '/admin/menus/grpuser',
            name: '사용자별그룹관리',
            component: () => import('@/views/admin/menus/GrpUserList.vue'),
            meta: {
              auth: true,
              permission: ['ADMIN'],
              usePop: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: '*',
    redirect: '/error',
  },
  {
    path: '/error',
    component: () => import('@/views/SubRouterView.vue'),
    name: '',
    children: [
      {
        path: '/error',
        component: () => import('@/views/NotFoundView.vue'),
        name: 'Error404',
        meta: { auth: false, navi: true },
      },
    ],
  },
];

export const router = new VueRouter({
  // mode: 'history',
  routes: getRoutes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  console.log('router index befaoreEach :' + !getIsLogin());
  // if (to.meta.auth == false) {
  //   return;
  // }
  if (to.meta.auth && !getIsLogin()) {
    next('/');
    //return;
  } else {
    next();
  }
});

/* router.beforeEach((to, from, next) => {
  if (to.meta.auth == true && vxLogonUser.isLogon() == false) {
    Vue.vxAlert(
      "인증정보가 유효하지 않습니다. 로그인 페이지로 이동합니다.",
      () => {
        vxLogonUser.clear();
        router.push("/");
      }
    );
  } else if (
    to.meta.auth == true &&
    to.meta.permission.indexOf(vxLogonUser.getPermission()) == -1
  ) {
    Vue.vxAlert("권한이 없습니다. 관리자에게 문의 바랍니다.");
  } else {
    next();
  }
});
 */
