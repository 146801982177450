import axios from 'axios';
import { setLogToken, getLogToken } from '@/utils/auth';
import bus from '@/utils/bus';
import qs from 'qs';

let VxHttpPlugin = {};
VxHttpPlugin.install = (Vue, { router }) => {
  router;

  const $axios = axios.create({
    baseURL: location.origin + '/api',
    // baseURL: location.origin + process.env.APP_BASE_PATH,
    withCredentials: true, // 기본값
  });

  //Interceptors
  $axios.interceptors.request.use(
    function (config) {
      //Before Request
      config.headers.common['Access-Control-Allow-Origin'] = '*';
      config.headers.userType = 'ADM';
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );
  $axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  function removeNullParams(params) {
    if (params == null) {
      return null;
    }
    let data = {};
    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined && value !== null) {
        data[key] = value;
      }
    }
    return data;
  }

  /*
  function hasInternalError(response) {
    // console.log(response);
    let responseData = response.data;
    if (
      Array.isArray(responseData) === true ||
      typeof responseData == 'string'
    ) {
      return false;
    }
    const { code: resCode, message } = responseData;
    // console.log(resCode, message);
    switch (resCode) {
      case 4001: //No Data
      case 200: //OK
        return false;
      case 1000: //Authentication Header Error
        throw { response: { status: 401 } };
      default:
        throw { response: { status: 500, data: message } };
    }
  }
  */

  function hasInternalError(response) {
    console.log('== hasInternalError', response);
    let responseData = response.data;
    if (
      Array.isArray(responseData) === true ||
      typeof responseData == 'string'
    ) {
      return false;
    }
    let totalCount = response.headers['x-erp-total-count'];
    const { status: status, data: message } = response;
    console.log('== hasInternalError() ', status, message, totalCount);
    //
    switch (status) {
      case 4001: //No Data
        return false;
      case 200: //OK
        return false;
      case 1000: //Authentication Header Error
        throw { response: { status: 401 } };
      default:
        throw { response: { status: 500, data: message } };
    }
  }

  function execute(method, url, data, options = {}) {
    data = removeNullParams(data);
    return new Promise(function (resolve, reject) {
      let params =
        method === 'get' || method === 'delete' ? { params: data } : { data };
      //
      //** List<String> orderColumns 배열 직렬화. add 2023.10.21
      if (method === 'get') {
        $axios.defaults.paramsSerializer = params => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        };
      }
      $axios.defaults.headers.common['Auth-Key'] = getLogToken();

      // console.log('url', url);
      $axios({ method, url, ...params, ...options })
        .then(function (response) {
          // console.log(response.data.authKey);
          if (response.data && response.data.authKey) {
            setLogToken(response.data.authKey);
          }
          if (hasInternalError(response) === false) {
            //resolve(response?.data || {});
            let totalCount = response.headers['x-erp-total-count'];
            let data = {
              ...response.data,
              totalCount: totalCount === null ? 0 : totalCount,
            };
            resolve(data);
          } else {
            //resolve(response);
            reject(response?.data || {});
          }
        })
        .catch(function (err) {
          if (axios.isCancel(err)) {
            // Vue.vxAlert('처리 중인 요청이 취소되었습니다.');
            resolve(err);
          } else {
            //HTTP STATUS 오류의 경우, vxHttp.catch로 예외를 처리할 수 없음
            doErrorHandler(err); //reject(err);
          }
        });
    });
  }

  function upload(method, url, data) {
    let formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (value !== null) {
        formData.append(key, value);
      }
    }
    /*
    let formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (value !== null) {
        console.log(
          '== upload key : ' +
            key +
            ', == upload value : ' +
            value +
            ', length : ' +
            value.length,
        );
        if (key === 'file' && value.length > 0) {
          const fileArray = Object.values(value);
          fileArray.map(file => {
            formData.append(key, file);
          });
        } else {
          formData.append(key, value);
        }
      }
    }
     */
    return execute(
      method,
      url,
      {},
      {
        enctype: 'multipart/form-data',
        processData: false,
        contentType: false,
        cache: false,
        data: formData,
      },
    );
  }

  function download(method, url, data, options) {
    return new Promise(function (resolve) {
      let params =
        method === 'get' || method === 'delete' ? { params: data } : { data };
      // console.log('url', url);
      $axios({ method, url, ...params, ...options })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (err) {
          resolve(err);
        });
    });
  }

  Vue.prototype.$vxHttp = {
    get: (url, params = null, options) => {
      return execute('get', url, params, options);
    },
    post: (url, data, options) => {
      return execute('post', url, data, options);
    },
    put: (url, data, options) => {
      return execute('put', url, data, options);
    },
    delete: (url, data, options) => {
      return execute('delete', url, data, options);
    },
    postFile: (url, data) => {
      return upload('post', url, data);
    },
    putFile: (url, data) => {
      return upload('put', url, data);
    },
    postDownFile: (url, data, options) => {
      return download('post', url, data, options);
    },
  };

  function doErrorHandler(err, vm, info) {
    vm;
    info;
    // eslint-disable-next-line no-debugger
    //debugger;
    //err.config && console.error('VUE.ERRORHANDLER', err.config);
    let { response } = { response: { status: 900 }, ...err };
    // console.log(err);
    switch (response.status) {
      case 400:
        Vue.vxAlert('요청이 유효하지 않습니다.');
        break;
      case 401:
        {
          bus.$emit('endLoader');
          Vue.vxAlert(
            '인증정보가 유효하지 않습니다. 로그인 페이지로 이동합니다.',
            () => router.push('/login').catch(() => {}),
          );
        }
        break;
      // case 403:
      //   router.push('/noPermissions');
      //   break;
      case 403:
        Vue.vxAlert('권한이 올바르지 않습니다.');
        break;
      case 404:
        {
          let { url } = err.config;
          Vue.vxAlert(`요청이 올바르지 않습니다. (${url})`, 'error');
        }
        break;
      case 500:
        Vue.vxAlert('처리 중 오류가 발생하였습니다.', 'error');
        //console.error((response.data && response.data) || 'ERROR!');
        //console.error(err.stack.replace(/\n[ ]*/g, ' --> '));
        break;
      case 900:
        Vue.vxAlert('스크립트 오류가 발생하였습니다.');
        break;
      default:
        Vue.vxAlert('서버에 알 수 없는 오류가 발생하였습니다.', 'error');
        break;
    }
  }

  Vue.config.errorHandler = (err, vm, info) => {
    //VXHTTP.MIXIN.ERROR_CAPTURED -> VUE.CONFIG.ERROR_HANDLER
    console.log('VUE.CONFIG.ERROR_HANDLER', err, vm, info);
    doErrorHandler(err, vm, info);
  };

  /*
  Vue.mixin({
    errorCaptured(err, vm, info) {
      console.log("VXHTTP.MIXIN.ERROR_CAPTURED", err, vm, info);
      //return false; //stopPropagation
    }
  })
  */
};
export default VxHttpPlugin;
