<template>
  <transition name="fade-view" mode="out-in">
    <div class="loader-wrapper" v-if="view">
      <div class="preloader">
        <span class="preloader-icon">Preloading</span>
        <div class="preloader-msg" v-html="setMsg"></div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    view: {
      type: Boolean,
      default: false,
    },
    msg: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    setMsg() {
      return this.msg.msg;
    },
    setInfo() {
      return this.msg.info;
    },
  },
};
</script>

<style></style>
