<template>
  <transition name="fade-view">
    <div class="dialog-wrapper custom" v-if="view">
      <div class="dialog-box" :style="{ width: width }">
        <span class="btn-close" @click="doClose" v-if="close">닫기</span>
        <div class="dialog-body" :style="{ height: height }">
          <slot></slot>
        </div>
        <div class="dialog-footer" v-if="useFooter">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    view: {
      type: Boolean,
      default: false,
      required: true,
    },
    close: {
      type: Boolean,
      default: true,
    },
    height: {
      type: String,
      default: 'auto',
    },
    width: {
      type: String,
      default: '400px',
    },
    useFooter: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    doClose() {
      this.$emit('closeDialog');
    },
  },
};
</script>
