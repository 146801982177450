// isLogin = {
// logState: true,
// companyUuid: '1ed2f165-b699-629b-981d-6742e5af32b4',
// companyCode: 'davada',
// companyName: '다바다',
// employeeId: 'loginId',
// employeeName: '강종철',
// companyInfo: '주류도매업체-다바다',
// permission: 'USER-00', //USER-00, USER-10
// };
//
// const logInfo = {
//   userId: '010-2270-9085',
//   userPwd: '2270',
// };

const state = {
  // isLogin: JSON.parse(sessionStorage.getItem('isLogin')) || null,
  // logToken: JSON.parse(sessionStorage.getItem('logToken')) || null,
  isLogin: JSON.parse(localStorage.getItem('isLogin')) || null,
  logToken: JSON.parse(localStorage.getItem('logToken')) || null,
  logInfo: JSON.parse(localStorage.getItem('logInfo')) || null,
  checkUserIdPwd: JSON.parse(localStorage.getItem('checkUserIdPwd')) || false,
};

const mutations = {
  setIsLogin(state, isLogin) {
    state.isLogin = isLogin;
  },
  setLogInfo(state, logInfo) {
    state.logInfo = logInfo;
  },
  setLogToken(state, logToken) {
    state.logToken = logToken;
  },
  setCheckUserIdPwd(state, checkUserIdPwd) {
    state.checkUserIdPwd = checkUserIdPwd;
  },
};

const getters = {
  getIsLogin(state) {
    return state.isLogin;
  },
  getLogState(state) {
    return state.isLogin.logState;
  },
  getWholesalerUuid(state) {
    return state.isLogin.wholesalerUuid;
  },
  getWholesalerCode(state) {
    return state.isLogin.wholesalerCode;
  },
  getWholesalerName(state) {
    return state.isLogin.wholesalerName;
  },
  getBusinessNumber(state) {
    return state.isLogin.businessNumber;
  },
  getCompanyUuid(state) {
    return state.isLogin.companyUuid;
  },
  getCompanyCode(state) {
    return state.isLogin.companyCode;
  },
  getCompanyName(state) {
    return state.isLogin.companyName;
  },
  getCompanyInfo(state) {
    return state.isLogin.companyInfo;
  },
  getEmployeeUuid(state) {
    return state.isLogin.employeeUuid;
  },
  getEmployeeId(state) {
    return state.isLogin.employeeId;
  },
  getEmployeeCode(state) {
    return state.isLogin.employeeCode;
  },
  getEmployeeName(state) {
    return state.isLogin.employeeName;
  },
  getAutoReleaseYn(state) {
    return state.isLogin.autoReleaseYn;
  },
  getPermission(state) {
    return state.isLogin.permission;
  },

  getLogInfo(state) {
    return state.logInfo;
  },

  getLogToken(state) {
    return state.logToken;
  },

  getCheckUserIdPwd(state) {
    return state.checkUserIdPwd;
  },
};

export const auth = {
  namespaced: true,
  state,
  mutations,
  getters,
};
