<template>
  <div class="location-wrap">
    <div class="auth-info">
      <!-- <span class="company-name">{{ companyName }}</span> -->
      <span class="user-info"
        ><strong>{{ wholesalerName }}</strong
        >님 환영합니다.</span
      >
    </div>
    <div class="location-box">
      <ul class="location">
        <li>Home</li>
        <li v-for="(item, index) in location" :key="index">
          {{ item }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getWholesalerName, getEmployeeName } from '@/utils/auth';
export default {
  data() {
    return {
      location: [],
      wholesalerName: '',
      userName: '',
    };
  },
  created() {
    if (this.$route.matched.length === 2 && !this.$route.matched[0].name) {
      this.location.push(this.$route.matched[1].name);
      return;
    }
    this.$route.matched.map(match => {
      this.location.push(match.name);
    });
  },
  mounted() {
    this.wholesalerName = getWholesalerName();
    this.userName = getEmployeeName();
  },
};
</script>

<style></style>
