import { store } from '@/store';

// isLogin = {
//   logState: true,
//   companyCode: '30002',
//   companyName: '다바다',
//   companyInfo: '쭈꾸미 삼겹살_유흥',
//   permission: 'USER-00'
// };

// 로그인 정보 저장
export const setIsLogin = resData => {
  store.commit('auth/setIsLogin', resData);
  sessionStorage.setItem('isLogin', JSON.stringify(resData));
};

//로그인 정보(전체정보)
export const getIsLogin = () => {
  return store.getters['auth/getIsLogin'];
};
//로그인 유무
export const getLogState = () => {
  return store.getters['auth/getLogState'];
};
//주류도매회사 고유키
export const getWholesalerUuid = () => {
  return store.getters['auth/getWholesalerUuid'];
};
//주류도매회사코드
export const getWholesalerCode = () => {
  return store.getters['auth/getWholesalerCode'];
};
//주류도매회사이름
export const getWholesalerName = () => {
  return store.getters['auth/getWholesalerName'];
};
//사업자번호
export const getBusinessNumber = () => {
  return store.getters['auth/getBusinessNumber'];
};
//매출거래처(소매점) 고유키
export const getCompanyUuid = () => {
  return store.getters['auth/getCompanyUuid'];
};
//매출거래처(소매점) 회사코드
export const getCompanyCode = () => {
  return store.getters['auth/getCompanyCode'];
};
//매출거래처(소매점) 회사이름
export const getCompanyName = () => {
  return store.getters['auth/getCompanyName'];
};
//회사정보
export const getCompanyInfo = () => {
  return store.getters['auth/getCompanyInfo'];
};
//사용자Uuid
export const getEmployeeUuid = () => {
  return store.getters['auth/getEmployeeUuid'];
};
//사용자ID
export const getEmployeeId = () => {
  return store.getters['auth/getEmployeeId'];
};
//사용자코드
export const getEmployeeCode = () => {
  return store.getters['auth/getEmployeeCode'];
};
//사용자이름
export const getEmployeeName = () => {
  return store.getters['auth/getEmployeeName'];
};
//자동출고여부
export const getAutoReleaseYn = () => {
  return store.getters['auth/getAutoReleaseYn'];
};
//권한정보
export const getPermission = () => {
  return store.getters['auth/getPermission'];
};
// 로그인 정보 삭제
export const deleteIsLogin = () => {
  store.commit('auth/setIsLogin', null);
  localStorage.removeItem('isLogin');
  //sessionStorage.removeItem("isLogin");
};

// 로그인 아이디/비번
export const setLogInfo = resData => {
  store.commit('auth/setLogInfo', resData);
  localStorage.setItem('logInfo', JSON.stringify(resData));
};
export const getLogInfo = () => {
  return store.getters['auth/getLogInfo'];
};
export const deleteLogInfo = () => {
  store.commit('auth/setLogInfo', null);
  localStorage.removeItem('logInfo');
};

//토큰정보 저장
export const setLogToken = token => {
  store.commit('auth/setLogToken', token);
  //sessionStorage.setItem('logToken', JSON.stringify(token));
  localStorage.setItem('logToken', JSON.stringify(token));
};
export const getLogToken = () => {
  return store.getters['auth/getLogToken'];
};
export const deleteLogToken = () => {
  store.commit('auth/setLogToken', null);
  //sessionStorage.removeItem('logToken');
  localStorage.removeItem('logToken');
};

// 아이디/비번 저장
export const setCheckUserIdPwd = checked => {
  store.commit('auth/setCheckUserIdPwd', checked);
  if (checked) {
    localStorage.setItem('checkUserIdPwd', checked);
  } else {
    localStorage.removeItem('checkUserIdPwd');
  }
};
// 아이디/비번 가져오기
export const getCheckUserIdPwd = () => {
  return store.getters['auth/getCheckUserIdPwd'];
};

// 현재접속 URL정보 가져오기
export const getCurrentDomainUrl = () => {
  let window_href = window.location.href;
  let pos = window_href.indexOf('dp');
  let currentDomainUrl = window_href.substring(0, pos) + 'api';
  return currentDomainUrl;
};
