<template>
  <div id="app">
    <transition name="fade-view-fast" mode="out-in">
      <router-view></router-view>
    </transition>
    <Loader :view="loadingStatus" :msg="msg"></Loader>
  </div>
</template>

<script>
import bus from '@/utils/bus.js';
import Loader from '@/components/common/Loader';
export default {
  name: 'App',
  components: { Loader },
  data() {
    return {
      msg: null,
      loadingStatus: false,
    };
  },
  methods: {
    startLoader(msg) {
      this.msg = msg;
      this.loadingStatus = true;
    },
    endLoader() {
      this.loadingStatus = false;
    },
  },
  created() {
    bus.$on('startLoader', this.startLoader);
    bus.$on('endLoader', this.endLoader);
  },
  mounted() {
    /* bus.$emit('startLoader', {
      msg: '서버 접속중입니다.<br>잠시만 기다려 주세요.',
    }); */
    // this.$vxAlert('경고문입니다.');
    /* this.$vxConfirm('삭제 하시겠습니까?', () => {
      console.log('삭제 실행~');
    }); */
  },
};
</script>

<style></style>
